<template>
  <section class="pt-20 pb-5">
    <div class="container max-w-screen-xl">
      <div class="row g-10 g-lg-32 align-items-center">
        <div class="col-md-6 col-lg-5">
          <div class="card bg-dark shadow border-0">
            <div class="position-relative">
              <img src="../../assets/images/wsk.jpg" class="card-img-top">
              <div class="shape-container shape-position-bottom text-dark">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                  <path d="M 0 0 c 0 0 200 50 500 50 s 500 -50 500 -50 v 101 h -1000 v -100 z" fill="currentColor"></path>
                </svg>
              </div>
            </div>
            <div class="card-body px-8 pb-8">
              <!-- Title -->
              <h2 class="h2 font-bold text-white">Wuraola S. Kayode</h2>
              <p class="text-muted">Social Worker | Programs Manager</p>

              <!-- Text -->
              <p class="text-white opacity-8 mt-4 mb-2">
                Be a part of something that spurs social change and create transformative benefits for your immediate community.
              </p>

              <div class="mt-lg-auto">
                <span class="mr-2">
                    <i class="bi bi-envelope"></i>
                </span>
                <a href="mailto:wura.agbeke@gmail.com" class="ml-2">
                  <small class="ml-2"> wura.agbeke@gmail.com</small>
                </a>

                <div class="row align-items-center mt-1">
                  <div class="col-xl-12">
                    <ul class="nav mb-0">
                      <li class="nav-item">
                        <a class="nav-link ps-0" target="_blank" href="https://www.linkedin.com/in/wuraola-kayode-6272a195/">
                          <i class="bi bi-linkedin"></i>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" target="_blank" href="https://twitter.com/wuraola_Kayode">
                          <i class="bi bi-twitter"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <h2 class="ls-tight font-bolder mb-5">
            Hello, World!
          </h2>
          <p class="text-muted mb-10">
            Wuraola Kayode is inspiring actions for child amputees and young persons with disabilities through her role as the Senior Programmes Manager at
            <a target="_blank" href="http://theiredefoundation.org">The IREDE Foundation</a>. She holds a bachelor’s degree in Psychology and a master degree (In-View) in Developmental Psychology.
          </p>

          <p class="text-muted mb-10">
            She has over 5 years of work experience in the social impact sector, leading and contributing to programmes and projects through collaborations with state governments, businesses, non-governmental organizations, and donor/funding organizations
          </p>

          <p class="text-muted mb-10">
            She has built expertise in program management, fundraising, grant & report writing, liaising with numerous stakeholders from various sectors, research and writing, human rights, disability rights, women's rights, gender equality, and child protection.
          </p>

          <p>
            <small class="text-muted mb-10">
              <strong>P.S I absolutely love arts in all diverse expressions. We should all be active music listeners!!!</strong>
            </small>
          </p>

          <div class="mt-4">
            <!-- Link -->
            <a target="_blank" href="/Wuraola-Kayode's-CV.pdf" class="btn d-inline-flex btn-warning btn-md">
                <span class=" ps-2 order-2">
                    <i class="bi bi-arrow-right-short"></i>
                </span>
                <span>View my Resume</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
    export default {
        name: 'Hero'
    }
</script>
