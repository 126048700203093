<template>
  <div id="app">
<!--    <Nav/>-->
    <Index/>
<!--    <Footer/>-->
  </div>
</template>

<script>
    // import Nav from './components/partials/Nav.vue'
    import Index from './components/Index.vue'
    // import Footer from './components/partials/Footer.vue'

    export default {
        name: 'App',
        components: {
            // Nav,
            Index,
            // Footer
        }
    }
</script>

<style>
html, body {
  overflow-x: hidden;
}
</style>